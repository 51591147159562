.loans-form-wrap {
    .card {
        padding: 0.3rem;
        background-color: #fff;
        border-radius: 0.2rem;

        .desc {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            color: #3f3f3f;
        }
    }

    .card.pd {
        margin-top: 0.3rem;
    }
    .card.pd .desc {
        padding: 0.4rem 0;
        border-bottom: 1px solid #ececec;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .title {
        position: relative;
        padding-left: 0.2rem;
        color: #3f3f3f;
        font-size: 16px;
        margin: 0.3rem 0;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -8px;
            content: '';
            width: 2px;
            height: 16px;
            background: #4489d7;
        }
    }

    .upload-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
    }

    .input-wrap {
        .day {
            padding: 0 0.1rem;
            color: #000;
            font-size: 18px;
            font-weight: 700;
            cursor: pointer;
        }

        .icon {
            position: relative;
            top: -0.1rem;
        }
    }
}
