.repayment-wrap {
    background-color: #f8f9f6;

    .navbar {
        --rv-nav-bar-background-color: rgb(53, 65, 72);
        --rv-nav-bar-icon-color: #fff;
        --rv-nav-bar-title-text-color: #fff;
    }
    .box1 {
        width: 100%;
        height: 3.6rem;
        background: #25292d;
        border-radius: 0 0 0.6rem 0.6rem;
    }

    .order-box {
        margin: -3rem 0.3rem 0;
        padding: 0.4rem 0.3rem;
        border-radius: 8px;
        background: #fff;
        text-align: center;

        .order-no {
            color: #595959;
            font-size: 16px;
        }

        .address {
            font-size: 12px;
        }

        .copy {
            display: inline-block;
            height: 34px;
            margin-top: 20px;
            padding: 0 10px;
            color: #5d96b2;
            font-size: 13px;
            line-height: 34px;
            border: 1px solid #5d96b2;
            border-radius: 20px;
            background: transparent;
            cursor: pointer;
            text-align: center;
        }
    }

    .title {
        padding: 0.4rem 0.3rem 0.2rem;
        color: #000;
        font-size: 15px;
    }

    .items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.2rem 0.32rem;
        color: #000;
        font-size: 14px;
        border-bottom: 1px solid #e7e7e7;
        background: #fff;
    }

    .upload-wrap {
        padding: 0.3rem;
        background: #fff;

        .text {
            margin-top: 0.3rem;
            color: #7c7c7c;
            font-size: 12px;
            text-align: center;
        }
    }

    .submit {
        width: auto;
        height: 1rem;
        color: #fff;
        font-size: 18px;
        line-height: 1rem;
        background: #588bf8;
        border-radius: 0.1rem;
        text-align: center;
        margin: 0.3rem;
    }
}
