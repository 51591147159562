@font-face {
    font-family: DINMittelschrift;
    src: url('~@/assets/fonts/DINMittelschrift.ttf');
}

@font-face {
    font-family: Verdanab;
    src: url('~@/assets/fonts/Verdanab.ttf');
}

@font-face {
    font-family: SFDisplayBold;
    src: url('~@/assets/fonts/SFDisplayBold.otf');
}

@font-face {
    font-family: SFDisplaySemibold;
    src: url('~@/assets/fonts/SFDisplaySemibold.otf');
}

@font-face {
    font-family: SFDisplayRegular;
    src: url('~@/assets/fonts/SFDisplayRegular.otf');
}

@font-face {
    font-family: SFDisplayBlack;
    src: url('~@/assets/fonts/SFDisplayBlack.otf');
}

html,
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Segoe UI, Arial, Roboto, PingFang SC, miui, Hiragino Sans GB, Microsoft Yahei,
        sans-serif;
    font-size: 0.28rem;
    background-color: #f5f7fb;
}

img {
    display: block;
}

p {
    margin: 0;
}

input {
    border: none;
}

a {
    color: inherit;
    text-decoration: none;
}

.f-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.f-center-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}
