.balance_wrap {
  margin-top: 0.48rem;
  background: #ffeed1;
  border-radius: 0.12rem;
  .record-tab {
    display: flex;
    align-items: center;

    .split-line {
      border: 1px solid #EEDFC4;
      //width: 1px;
      height: 20px;
    }
  }

  .record {
    color: #113063;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0.32rem 0;
    font-weight: 700;
    font-family: SFDisplayBold;
    flex: 1;
    font-size: 12px;
    .icon {
      margin-left: 0.12rem;
      width: 0.24rem;
    }

    .icon-left {
      height: 14px;
      margin-right: 6px;
    }
  }
  .info {
    background: linear-gradient(90deg,#fd9a5c,#fbe6b7);
    border-radius: 0.12rem;
    padding: 0.24rem;
    .balance {
      padding-top: 0.16rem;
      .name {
        color: rgba(17,48,99,.4);
        font-size: .24rem;
      }
      .value {
        color: #113063;
        font-size: .6rem;
        font-family: DINMittelschrift;
      }
    }
    .other {
      margin-top: 0.36rem;
      display: flex;
      justify-content: space-between;
      background: #fff;
      border-radius: 0.12rem;
      padding: 0.24rem 0;
      .item {
        width: 50%;
        text-align: center;
        &:first-child {
          border-right: 1px solid #eeeef0;
        }
        .name {
          color: rgba(17,48,99,.4);
          font-size: .24rem;
        }
        .value {
          margin-top: 0.12rem;
          color: #113063;
          font-size: .32rem;
          font-family: DINMittelschrift;
        }
      }
    }
  }
}
