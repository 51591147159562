.share_info {
  position: relative;
  padding: 0.5rem 0.32rem;
  text-align: center;
  .info {
    text-align: center;
    padding: 0 0.18rem;
    font-size: .32rem;
    .title {
      color: #142339;
      font-family: SFDisplayBold;
      .num {
        color: #4b69fe;
      }
    }
    .content {
      margin-top: 0.14rem;
      color: rgba(0,0,0,.5);
      font-size: .24rem;
      font-family: SFDisplayRegular;
    }
  }
  .link_wrap {
    position: relative;
    margin: 0 auto;
    margin-top: 0.4rem;
    padding: 0 1.7rem 0 0.36rem;
    height: 0.88rem;
    line-height: .88rem;
    background: #f9fafb;
    border-radius: 0.1rem;
    border: 1px solid #e6eaee;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: .26rem;
    color: #000;
    font-family: SFDisplayRegular;
    .copy {
      position: absolute;
      right: 0.16rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 1.3rem;
      height: 0.56rem;
      line-height: .56rem;
      background: #e9edef;
      color: #4e4b66;
      border-radius: 0.29rem;
      text-align: center;
    }
  }
  .share_bth {
    margin-top: 0.48rem;
    width: 100%;
    height: 0.98rem;
    line-height: .98rem;
    background: linear-gradient(90deg,#3875f6,#53a4f3);
    border-radius: 0.08rem;
    text-align: center;
    color: #fff;
    font-family: SFDisplayBold;
  }
}
