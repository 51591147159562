.share_page {
  padding: 0.4rem 0.32rem 0.48rem;
  .banner {
    width: 100%;
  }
  .main_wrap {
    position: relative;
    margin-top: -1rem;
    background: #fff;
    margin-bottom: 0.6rem;
    border-radius: 0.2rem;
    .reward_wrap {
      padding: 0.4rem 0;
      margin: 0 0.32rem;
      border-bottom: 1px solid #eceff6;
    }
  }
}
