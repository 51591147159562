.home_page {
  padding: 0.4rem 0.32rem 0.48rem;
  .banner {
    width: 100%;
  }
  .bottom_over {
    margin-top: 0.48rem;
    text-align: center;
    color: #cacaca;
    font-size: .24rem;
  }
}
