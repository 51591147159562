.withdraw_wrap {
  margin-top: 0.4rem;
  padding: 0.32rem 0.24rem;
  background: #fff;
  border-radius: 0.12rem;

  .chain-selection {
    .option-list {
      display: flex;
      margin-top: 10px;
      margin-bottom: 15px;


      .item {
        background: #FAFAFA;
        border-radius: 16px;
        width: 78px;
        height: 30px;
        font-weight: 400;
        color: #3E536C;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .item.active {
        background: linear-gradient(90deg, #4A74F0 0%, #5EA2F2 100%);
        color: #FFFFFF;
      }
    }
    .pay-list{      
        justify-content: space-around;
    .item {
       width: 86px;
       height: 32px;
       line-height: 32px;
    }
    .item.active {
        background: #194BEA ;
        color: #FFFFFF;
      }
    }
    .img-list{      
        justify-content: space-around;
        img{
            width: 26px;
            height: 26px;
            padding: 7px;
  
            border-radius: 26px;
        }
        img.active{
            border:1px solid;
        }
    }
  }

  .title {
    color: #3e536c;
    font-size: .26rem;
  }
.titleMoneyBox{
  position: relative;
}
.titleMoney{
  position: absolute;
  right: 0;
}
  .input_wrap {
    margin-top: 0.16rem;
    position: relative;
    height: 0.88rem;
    background: #f9fafb;
    border: 1px solid #e6eaee;
    border-radius: 0.12rem;

    .input {
      width: 100%;
      height: 100%;
      padding-left: 0.4rem;
      padding-right: 1.8rem;
      box-sizing: border-box;
      background: transparent;
      color: #081724;
      font-size: .56rem;
      font-weight: 700;
      font-family: DINMittelschrift;
    }

    .max {
      position: absolute;
      right: 0.16rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 1.3rem;
      height: 0.56rem;
      line-height: .56rem;
      background: #e9edef;
      border-radius: 0.28rem;
      color: #4083f5;
      font-size: .24rem;
      text-align: center;
    }

    .address {
      font-size: 14px !important;
      padding-right: 0.4rem !important;
    }
  }

  .cash_tips {
    margin-top: 0.32rem;
    font-size: .26rem;
    color: rgba(62, 83, 108, .6);
  }

  .submit_btn {
    margin-top: 0.5rem;
    height: 0.96rem;
    line-height: .96rem;
    text-align: center;
    background: linear-gradient(90deg, #3875f6, #53a4f3);
    border-radius: 0.12rem;
    color: #fff;
    font-family: SFDisplayBold;
  }
}
.dashed-line {
    width: 100%;
    height: 2px;
    background-image: linear-gradient(to right, #E7ECEE 50%, transparent 50%);
    background-size: 30px 1px;
    margin-bottom: 14px;
  }
  .displayNone{
    display: none!important;
  }
  .displayBlock{
    display: block!important;
  }
  .address-copy{
    display: flex;
    justify-content:center;
    padding: 10px 0 20px 0;
  }
  .copy-address{
    display: flex;
    justify-content:center;
    color: #194BEA;
    padding: 0px 0 20px 0;
  }