.reward {
  background: #f5f7fb;
  border-radius: 0.12rem;
  padding: 0.36rem 0.32rem;
  font-family: SFDisplayRegular;
  .main_value {
    text-align: center;
    .name {
      color: rgba(17,48,99,.4);
      font-size: .24rem;
      margin-bottom: 0.08rem;
    }
    .value {
      color: #113063;
      font-size: .4rem;
      font-family: DINMittelschrift;
      font-weight: 700;
    }
  }
  .sub_value {
    margin-top: 0.38rem;
    display: flex;
    justify-content: space-between;
    .item {
      width: 50%;
      text-align: center;
      &:first-child {
        border-right: 1px solid #eeeef0;
      }
      .name {
        color: rgba(17,48,99,.4);
        .icon {
          display: inline-block;
          width: 0.32rem;
          margin-right: 0.1rem;
        }
      }
      .value {
        margin-top: 0.12rem;
        font-family: DINMittelschrift;
        font-size: .32rem;
      }
    }
  }
}
