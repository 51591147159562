.withdraw_wrap {
  margin-top: 0.4rem;
  padding: 0.32rem 0.24rem;
  background: #fff;
  border-radius: 0.12rem;
  position: relative;


  .title {
    color: #3e536c;
    font-size: .26rem;
    display: flex;
    align-items: center;

    img {
      width: 16px;
      margin-right: 5px;
    }

    .bind-bankcard {
      position: absolute;
      right: 15px;
      color: #3e7dff;
    }
  }

  .form {
    margin-top: 15px;

    .item {
      margin-bottom: 15px;

      .key {
        font-size: 14px;
        font-weight: 400;
        color: #3E536C;
        line-height: 20px;
      }

      .value {
        height: 40px;
        background: #FAFAFA;
        border-radius: 4px;
        border: 1px solid #CCCCCC;
        display: flex;
        align-items: center;
        padding: 0 15px;
        margin-top: 10px;
      }
    }
  }

  .bankcard-list {
    margin-top: 10px;
    position: relative;

    // select, option {
    //   width: 100%;
    //   height: 45px;
    //   background: #FAFAFA;
    //   border-radius: 4px;
    //   border: none;
    //   padding: 0 15px;
    //   font-size: 14px;
    //   font-weight: 500;
    //   color: #14243A;
    // }

    // option {
    //   appearance: none;
    //   position: absolute;
    //   bottom: 0;
    // }
    .item {
       display: flex;
       margin-bottom: 15px;
       padding:.12rem 14px .13rem 15px ;
       background-color: #FAFAFA!important;
border-radius: 0.04rem;
opacity: 0.9;

       .leftName {
        display: flex;
        align-items: center;
        span {
            margin-left: 8px;
        }
       }
    }
    .active {
        border: 1px solid #194BEA;
    }
  }

  .input_wrap {
    margin-top: 0.16rem;
    position: relative;
    height: 0.88rem;
    background: #f9fafb;
    border: 1px solid #e6eaee;
    border-radius: 0.12rem;

    .input {
      width: 100%;
      height: 100%;
      padding-left: 0.4rem;
      padding-right: 1.8rem;
      box-sizing: border-box;
      background: transparent;
      color: #081724;
      font-size: .56rem;
      font-weight: 700;
      font-family: DINMittelschrift;
    }

    .max {
      position: absolute;
      right: 0.16rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 1.3rem;
      height: 0.56rem;
      line-height: .56rem;
      background: #e9edef;
      border-radius: 0.28rem;
      color: #4083f5;
      font-size: .24rem;
      text-align: center;
    }
  }

  .cash_tips {
    margin-top: 0.32rem;
    font-size: .26rem;
    color: rgba(62, 83, 108, .6);
  }

  .submit_btn {
    margin-top: 0.5rem;
    height: 0.96rem;
    line-height: .96rem;
    text-align: center;
    background: linear-gradient(90deg, #3875f6, #53a4f3);
    border-radius: 0.12rem;
    color: #fff;
    font-family: SFDisplayBold;
  }
}
