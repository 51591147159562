@font-face{
  font-family: News706BTBold;
  src: url('~@/assets/fonts/News706BTBold.ttf');
}
.coinbase_page {
  min-height: 100vh;
  padding-bottom: 1px;
  background: #D3E4FA;
  .header-nav {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 0 0.4rem;
    height: 0.8rem;
    background: #E0ECFB;
    .logo {
      height: 0.36rem;
    }
  }
  .img1 {
    width: 4rem;
    margin: 1.2rem 1.78rem 1.74rem 1.72rem;
  }
  .img2 {
    width: 4.48rem;
    margin: 2.54rem 1.48rem 1.4rem 1.54rem;
  }
  .img3 {
    width: 4.28rem;
    margin: 2.64rem 1.68rem 1.52rem 1.54rem;
  }
  .img4 {
    width: 4.04rem;
    margin: 2.78rem 1.44rem 1.62rem 2.02rem;
  }
  .img5 {
    width: 5.20rem;
    margin: 2.24rem 9.2rem 1.2rem 1.38rem;
  }
  .img6 {
    width: 3.88rem;
    margin: 2.46rem 1.8rem 1.62rem 1.82rem;
  }
  .img7 {
    width: 5.20rem;
    margin: 2.24rem 1.14rem 1.2rem 1.16rem;
  }

  .title {
    margin-bottom: 0.3rem;
    padding: 0 0.6rem;
    font-family: News706BTBold;
    font-size: 0.4rem;
    color: #333;
  }
  .desc {
    margin-bottom: 0.2rem;
    padding: 0 0.6rem;
    font-size: 0.28rem;
    font-weight: 500;
    color: #444;
  }
  .tips {
    padding: 0 0.6rem;
    font-size: 0.24rem;
    color: #444;
  }
}
