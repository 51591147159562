.online_help_popup_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .popup_header {
    position: relative;
    text-align: center;
    color: #131f30;
    font-size: .36rem;
    padding: 0.32rem 0;
    .left_back {
      position: absolute;
      left: 0.32rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  .iframe_wrap {
    flex: 1;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
