.question {
  margin-top: 0.4rem;
  margin-bottom: 0.64rem;
  padding: 0.2rem 0.32rem 0 0.32rem;
  background: #fff;
  border-radius: 0.12rem;
  .item {
    padding: 0.32rem 0 0.3rem;
    border-bottom: 1px solid rgba(67,67,67,.07);
    &:last-child {
      border: none;
    }
    .title {
      display: flex;
      align-content: center;
      align-items: center;
      color: rgba(0,0,0,.8);
      font-weight: 700;
      .icon {
        margin-right: 0.2rem;
        width: 0.4rem;
        height: 0.4rem;
      }
    }
    .content {
      margin-top: 0.24rem;
      color: rgba(0,0,0,.5);
    }
  }
}
