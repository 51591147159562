.official_website {
  margin-top: 0.74rem;
  display: flex;
  justify-content: space-between;
  .item {
    padding: 0.68rem 0.16rem 0.24rem 0.16rem;
    position: relative;
    width: 2.12rem;
    box-sizing: border-box;
    background: linear-gradient(90deg,#fdd75c,#fbe6b7);
    border-radius: 0.12rem;
    text-align: center;
    .logo {
      position: absolute;
      left: 0;
      right: 0;
      top: -0.4rem;
      margin: auto;
      width: 1.02rem;
      height: auto;
    }
    .name {
      height: 0.8rem;
      line-height: .8rem;
      background: #fff;
      border-radius: 0.08rem;
      color: #309bff;
      font-size: .24rem;
    }
  }
}
