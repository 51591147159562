.upload {
    position: relative;
display: flex;
justify-content: center;
    width: 100%;
    margin-bottom: 0.3rem;
    &-img {
        width: 48%;
        height: 2rem;
    }

    // &-input {
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     opacity: 0;
    //     z-index: 1;
    // }

    .sub-title {
        margin-top: 0.1rem;
        color: #7c7c7c;
        font-size: 14px;
        text-align: center;
    }
}
