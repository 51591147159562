.total_data {
  margin-bottom: 0.4rem;
  padding: 0 0.2rem 0.2rem 0.2rem;
  background: #fff;
  border-radius: 0.12rem;
  .address_info {
    padding: 0.4rem 0 0.46rem 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    .name {
      .title {
        // white-space: nowrap;
        color: #495666;
        font-size: .32rem;
        font-weight: 700;
      }
      .sub_title {
        margin-top: 0.12rem;
        color: #3e536c;
        font-size: .24rem;
      }
    }
    .value {
      width: 2.24rem;
      height: 0.68rem;
      background: rgba(158,219,182,.2);
      color: rgba(119,134,152,.89);
      border-radius: 0.34rem;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      .icon_link {
        margin-right: 0.12rem;
        width: 0.32rem;
      }
    }
  }
  .amount_info {
    padding: 0.32rem 0.12rem;
    background: #f5f7fb;
    border-radius: 0.12rem;
    display: flex;
    justify-content: space-between;
    .amount_item {
      width: 50%;
      text-align: center;
      &:first-child {
        border-right: 1px solid #eeeef0;
      }
      .item_name {
        height: 0.44rem;
        line-height: .44rem;
        color: #8995a4;
        font-size: .24rem;
      }
      .item_value {
        margin-top: 0.04rem;
        color: #142339;
        font-size: .4rem;
        font-family: DINMittelschrift;
      }
    }
  }
  .switch-demo {
    margin-top: 15px;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background: linear-gradient(90deg, #4A74F0 0%, #5EA2F2 100%);
      border-radius: 4px;
      font-size: 14px;
      color: #FFFFFF;
      font-weight: 500;
    }
  }
}
.demo-popup {
  padding-bottom: 25px;
  .popup-header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px;
    border-bottom: 1px solid #EEEEEE;
    img {
      width: 16px;
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #3E536C;
    }

    .close {
      position: absolute;
      right: 15px;
    }
  }

  .content {
    padding: 24px 20px 37px;
    .list {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 16px;
      font-weight: 500;

      .item {
        width: 90px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .active {
        background: linear-gradient(90deg, #4A74F0 0%, #5EA2F2 100%);
        border-radius: 16px;
        color: #FFFFFF;
      }
    }
  }

  .btn {
    height: 50px;
    background: linear-gradient(90deg, #4A74F0 0%, #5EA2F2 100%);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
  }
}



