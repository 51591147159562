.join_popup_container {
  background: transparent;
  .join_popup {
    width: 6.86rem;
    background: #fff;
    border-radius: 0.2rem;
    padding: 0.4rem 0;
    position: relative;
    .header {
      position: relative;
      text-align: center;
      .join_node {
        display: inline-block;
        width: 5rem;
        height: auto;
      }
      .icon_close {
        position: absolute;
        top: 0;
        right: 0.4rem;
        width: 0.4rem;
        z-index: 99;
      }
    }
    .content_wrap {
      position: relative;
      padding: 0 0.4rem;
      max-height: 6rem;
      overflow: auto;
      .title {
        margin-top: 0.1rem;
        color: #142339;
        font-size: .6rem;
        font-weight: 700;
        font-family: SFDisplayBlack;
      }
      .content {
        margin-top: 0.24rem;
        color: rgba(62,83,108,.8);
        font-family: Verdana;
      }
      .join_btn {
        margin-top: 0.4rem;
        height: 0.98rem;
        line-height: .98rem;
        background: linear-gradient(90deg,#3875f6,#53a4f3);
        border-radius: 0.08rem;
        color: #fff;
        font-size: .28rem;
        font-family: Verdanab;
        text-align: center;
      }
    }
  }
}
