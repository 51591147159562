.amm_introduce_popup_container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #f5f7fb;
  background-image: linear-gradient(180deg, #386ef6, #448af5 49%, rgba(83,164,243,0));
  background-size: 100% 8.75rem;
  background-repeat: no-repeat;
  .popup_header {
    position: relative;
    text-align: center;
    color: #fff;
    font-size: .36rem;
    padding: 0.32rem 0;
    .left_back {
      position: absolute;
      left: 0.32rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  .content_wrap {
    margin-top: 0.32rem;
    padding: 0 0.32rem;
    position: absolute;
    top: 0.9rem;
    left: 0;
    right: 0;
    bottom: 1.76rem;
    overflow: auto;
    .intro_detail {
      margin-top: 0.32rem;
      background: #fff;
      border-radius: 0.16rem;
      padding: 0.3rem 0.24rem;
      .item {
        line-height: 1.25;
        color: #1a293e;
      }
      .item + .item {
        margin-top: 0.4rem;
      }
    }
  }
}
