.record_popup_container {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #386ef6, #448af5 49%, rgba(83, 164, 243, 0));
  background-size: 100% 8.75rem;
  background-repeat: no-repeat;

  .popup_header {
    position: relative;
    padding: 0.32rem;
    text-align: center;
    font-size: .36rem;
    color: #fff;

    .icon_left {
      position: absolute;
      left: 0.32rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0.4rem;
    }
  }

  .popup_content {
    position: relative;
    padding: 0.32rem;

    .bind-bankcard-form, .bankcard-list {
      .title {
        font-weight: 7000;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .bankcard-list {
      .item {
        background: #FFFFFF;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info {
            display: flex;
        align-items: center;
        }
img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}
        .eye {
          margin-right: 15px;
        }
      }
    }

    .bind-bankcard-form {
      margin-top: 15px;
    }
  }
}
.cardPopup{
    box-sizing: border-box;
    border-radius: 0.11rem;
    width: 5.98rem;
    padding: 14px 0 20px 14px;
    // height:144px;
}
.lookCardInfo {
    width: 100%;
    height:100%;
    div {
        span {
            font-weight: 600;
          margin-right: 3px;
        }
        color: #172435 ;
        font-size: .25rem;
        padding: .1rem 0;
    }
}
