.unable_connect_page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 6.86rem;
  height: 5.92rem;
  background: #4b69fe;
  border-radius: 0.2rem;
  .top_img {
    position: absolute;
    left: 0;
    right: 0;
    top: -1.36rem;
    margin: auto;
    width: 2.4rem;
    height: auto;
  }
  .content_warp {
    padding: 0 0.4rem;
    padding-top: 1.76rem;
    text-align: center;
    .title {
      font-size: .48rem;
      color: #fff;
      font-family: Verdanab;
    }
    .content {
      margin-top: 0.24rem;
      color: rgba(255, 255, 255, 0.8);
      font-size: .3rem;
    }
    .copy {
      margin: 0 auto;
      margin-top: 0.6rem;
      width: 5.12rem;
      height: 0.98rem;
      line-height: .98rem;
      background: #fff;
      border-radius: 0.16rem;
      color: #4b69fe;
      text-align: center;
      font-family: Verdanab;
    }
  }
}
