.card_info {
  margin-top: 0.48rem;
  padding: 0 0.24rem;
  background: #fff;
  border-radius: 0.12rem;
  .item {
    padding: 0.36rem 0;
    border-bottom: 1px solid #f0f3ff;
    color: #3e536c;
    display: flex;
    justify-content: space-between;
    &:last-child {
      border: none
    }
    .value {
      color: #081724;
      font-size: .4rem;
      font-family: DINMittelschrift;
      .symbol {
        color: #6d87a8;
        font-size: .28rem;
      }
    }
  }
  .arrow_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-left: 0.08rem;
      width: 0.24rem;
    }
  }
}
