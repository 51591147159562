.mortgage {
  margin-top: 0.4rem;
  // margin-bottom: 1rem;
  margin-bottom: 0.4rem;
  .title_content {
    display: flex;
    align-items: center;
    height: 1.44rem;
    padding: 0 0.32rem;
    color: #9696b5;
    // border-radius: 0.3rem 0.3rem 0 0;
    border-radius: 0.12rem 0.12rem 0 0;
    background: linear-gradient(224deg, #e7f2f5, #f2f0f1);
    .icon {
      width: auto;
      height: 0.58rem;
      margin-right: 0.32rem;
    }
    .right {
      font-family: SFDisplaySemibold;
      font-size: 0.36rem;
      .sub_title {
        margin-top: 0.08rem;
        font-size: 0.24rem;
        color: rgba(150, 150, 181, 0.52);
      }
    }
  }
  .main_content {
    position: relative;
    z-index: 1;
    padding: 0 0.32rem;
    // border-radius: 0 0 0.3rem 0.3rem;
    border-radius: 0 0 0.12rem 0.12rem;
    background: #fff;
    // box-shadow: 6px 12px 20px 0 rgba($color: #000000, $alpha: 0.05);
    // &::before {
    //   content: '';
    //   position: absolute;
    //   bottom: -0.24rem;
    //   left: 0.24rem;
    //   right: 0.24rem;
    //   height: 0.6rem;
    //   z-index: -1;
    //   border-radius: 0 0 0.3rem 0.3rem;
    //   background: #fff;
    //   box-shadow: 6px 12px 20px 0 rgba($color: #000000, $alpha: 0.05);
    // }
    // &:after {
    //   content: '';
    //   position: absolute;
    //   bottom: -0.48rem;
    //   left: 0.48rem;
    //   right: 0.48rem;
    //   z-index: -2;
    //   height: 0.6rem;
    //   border-radius: 0 0 0.3rem 0.3rem;
    //   background: #fff;
    //   box-shadow: 6px 12px 20px 0 rgba($color: #000000, $alpha: 0.05);
    // }
    .text_intro {
      padding: 0.32rem 0;
      font-size: 0.24rem;
      color: rgba(150, 150, 181, 0.8);
      border-bottom: 1px solid rgba(216, 216, 216, 0.5);
    }
    .value_list {
      padding-top: 0.32rem;
      font-size: 0.2rem;
      border-bottom: 1px solid rgba(216, 216, 216, 0.5);
      color: #1e1e1e;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.34rem;
        font-size: 0.28rem;
        .name {
          color: #a1a1b3;
        }
        .value {
          font-family: SFDisplayBold;
          font-weight: 700;
          color: #000;
        }
      }
    }
    .progress-container {
      margin-top: 0.34rem;
      .progress-wrap {
        overflow: hidden;
        position: relative;
        display: flex;
        &::after {
          content: '';
          position: absolute;
          top: -50%;
          left: 50%;
          display: block;
          width: 0.16rem;
          height: 200%;
          background: #fff;
          transform: translateX(-50%) rotate(25deg);
        }
        .item {
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 0.36rem;
          span {
            box-sizing: border-box;
            position: absolute;
            display: block;
            max-width: 100%;
            min-width: 4.5em;
            line-height: 0.36rem;
            text-align: center;
            font-size: 0.22rem;
            font-weight: 500;
            color: #fff;
            &::after {
              content: '';
              position: absolute;
              top: -50%;
              display: block;
              width: 0.16rem;
              height: 200%;
              transform: rotate(25deg);
            }
          }
        }
        .item:first-child {
          border-radius: 100px 0 0 100px;
          background: #EDF1FE;
          span {
            left: 0;
            padding: 0 1em 0 0.5em;
            background: linear-gradient(270deg, #3389F9 0%, #1751F0 100%);
            &::after {
              right: -0.08rem;
              background: #EDF1FE;
            }
          }
        }
        .item:last-child {
          border-radius: 0 100px 100px 0;
          background: #FEF5ED;
          span {
            right: 0;
            padding: 0 0.5em 0 1em;
            background: linear-gradient(270deg, #F96033 0%, #F07B17 100%);
            &::after {
              left: -0.08rem;
              background: #FEF5ED;
            }
          }
        }
      }
      .label {
        display: flex;
        justify-content: space-between;
        margin-top: 0.1rem;
        .item {
          font-size: 0.22rem;
        }
        .item:first-child {
          span {
            color: #1751F0;
          }
        }
        .item:last-child {
          text-align: right;
          span {
            color: #FF5C00;
          }
        }
      }
    }
    .status {
      margin-top: 0.16rem;
      font-family: SFDisplayBold;
      text-align: center;
      font-weight: 500;
      color: #8CA8F9;
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.66rem;
      line-height: 0.8rem;
      margin: 0.24rem auto 0;
      font-family: SFDisplayBold;
      font-size: 0.24rem;
      color: #fff;
      border-radius: 0.1rem;
      background: #1652F0;
      &.is-disabled {
        background: rgba(22, 82, 240, 0.5);
      }
    }
    .footer_content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.44rem;
      padding-bottom: 0.56rem;
      font-size: 0.24rem;
      color: #292d32;
      .item {
        display: flex;
        align-content: center;
        align-items: center;
        .icon {
          width: 0.26rem;
          height: 0.26rem;
          margin-right: 0.08rem;
        }
      }
    }
  }
}
