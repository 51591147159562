.order_popup_container {
  width: 100%;
  height: 100%;
  background: #f5f7fb;
  .popup_header {
    position: relative;
    text-align: center;
    color: #131f30;
    font-size: .36rem;
    padding: 0.32rem 0;
    .left_back {
      position: absolute;
      left: 0.32rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  .popup_content {
    margin-top: 0.32rem;
    padding: 0 0.32rem;
    .order_item {
      background: #fff;
      border-radius: 0.16rem;
      padding: 0.32rem;
      margin-bottom: 0.32rem;
      .item_info {
        padding-bottom: 0.36rem;
        display: flex;
        justify-content: space-between;
        .name {
          color: #6d87a8;
        }
        .value {
          display: flex;
          align-content: center;
          align-items: center;
          color: #3e536c;
          .icon_usdt {
            width: 0.4rem;
            height: 0.4rem;
            margin-right: 0.12rem;
          }
          .type_name {
            color: #3e7dff;
          }
          .amount_num {
            color: #131f30;
          }
          .amount_symbol {
            font-size: .24rem;
          }
          .order_status {
            &.progress {
              color: #20bd42;
            }
            &.expired {
              color: #ff9f39;
            }
          }
        }
      }
      .item_logos {
        height: 0.88rem;
        background: #f5f7fb;
        border-radius: 0.12rem;
        padding-left: 0.24rem;
        display: flex;
        align-content: center;
        align-items: center;
        .logo_icon {
          width: 0.4rem;
          height: 0.4rem;
          margin-right: 0.24rem;
        }
      }
    }
  }
  .progress-container {
    width: 3rem;
    .progress-wrap {
      overflow: hidden;
      position: relative;
      display: flex;
      .item {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 0.36rem;
        border-radius: 100px;
        background: #EDF1FE;
        span {
          box-sizing: border-box;
          position: absolute;
          right: 0;
          display: block;
          max-width: 100%;
          min-width: 3.5em;
          line-height: 0.36rem;
          text-align: center;
          font-size: 0.22rem;
          font-weight: 500;
          color: #fff;
          background: linear-gradient(270deg, #1751F0 0%, #3389F9 100%);
          &::after {
            content: '';
            position: absolute;
            top: -50%;
            display: block;
            width: 0.16rem;
            height: 200%;
            transform: rotate(25deg);
          }
        }
      }
    }
    .label {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.1rem;
      .item {
        text-align: right;
        font-size: 0.22rem;
        span {
          color: #1751F0;
        }
      }
    }
  }
}
