.record_popup_container {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #386ef6, #448af5 49%, rgba(83,164,243,0));
  background-size: 100% 8.75rem;
  background-repeat: no-repeat;
  .popup_header {
    position: relative;
    padding: 0.32rem;
    text-align: center;
    font-size: .36rem;
    color: #fff;
    .icon_left {
      position: absolute;
      left: 0.32rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0.4rem;
    }
  }
  .popup_content {
    position: relative;
    padding: 0.32rem;
    .record_content {
      .record_item {
        margin-bottom: 0.16rem;
        padding: 0.32rem 0.24rem;
        background: #f5f7fb;
        border-radius: 0.12rem;
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        .type_content {
          display: flex;
          align-content: center;
          align-items: center;
          font-size: .24rem;
          .icon_usdt {
            display: inline-block;
            width: 0.6rem;
            margin-right: 0.24rem;
          }
          .type_info {
            .name {
              font-weight: 700;
              color: #14a46c;
            }
            .date {
              margin-top: 0.16rem;
              color: rgba(0,0,0,.5);
            }
          }
        }
        .amount_content {
          .coin_amount {
            color: #000;
            font-family: DINMittelschrift;
            font-size: .36rem;
          }
        }
      }
      .no_data {
        position: relative;
        padding: 1.44rem 0;
        text-align: center;
        img {
          display: inline-block;
          width: 2.7rem;
          height: auto;
        }
        .no_info {
          margin-top: 0.32rem;
          color: #c3cbd6;
        }
      }
    }
  }
}
