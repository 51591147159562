.pledge_page {
  min-height: 80vh;
  padding: 0.4rem 0.32rem 0.48rem;
  .action_wrap {
    margin-top: 0.48rem;
    display: flex;
    justify-content: space-between;
    .item {
      width: 3.28rem;
      height: 0.68rem;
      line-height: .68rem;
      text-align: center;
      border-radius: 0.08rem;
      border: 1px solid #fff;
      font-size: .26rem;
      color: #fff;
      font-weight: 500;
    }
  }
}
