.partner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.48rem;
  .item:nth-child(3n - 2) {
    width: 2.9rem;
  }
  .item:nth-child(3n - 1) {
    width: 1.98rem;
  }
  .item:nth-child(3n) {
    width: 1.94rem;
  }
  .item:nth-child(1n + 4) {
    margin-top: 0.24rem;
  }
  img {
    height: 0.36rem;
    width: auto;
    display: inline-block;
  }
}
