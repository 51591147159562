.online_help_popup_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .navbar {
        --rv-nav-bar-background-color: #25282d;
        --rv-nav-bar-icon-color: #fff;
        --rv-nav-bar-title-text-color: #fff;
        --rv-nav-bar-text-color: #fff;
    }

    .content_wrap {
        flex: 1;
        overflow: scroll;
        background: #f4f4f4;

        .header-wrap {
            width: 100%;
            background-color: #25282d;
            background-color: #25282d;
            padding-top: 0.4rem;
            padding-bottom: 1.4rem;

            .circle-box {
                width: 100%;
                color: #fff;
                font-size: 0.42rem;
                background: url(./assets/circle-bg.png) no-repeat center 0.12rem !important;
                background-size: 4.8rem 2.8rem !important;
                text-align: center;
                padding-bottom: 0.8rem;

                color: #fff;
                font-size: 16px;

                .s1 {
                    margin-top: 30px;
                }

                .s2 {
                    margin-top: 20px;

                    .number {
                        font-size: 20px;
                    }
                }
            }

            .btn {
                color: #fff;
                font-size: 12px;
                background: #3578fd;
                border-radius: 50px;
                width: 2rem;
                height: 0.5rem;
                line-height: 0.5rem;
                text-align: center;
                transform: translateY(-50%);
            }

            .infos {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                width: 80%;

                .items {
                    color: #969799;
                    font-size: 14px;
                    text-align: center;

                    .t2 {
                        position: relative;
                        padding-top: 0.2rem;
                        color: #fff;
                        font-size: 16px;

                        &::after {
                            position: absolute;
                            left: 50%;
                            bottom: -0.36rem;
                            content: '';
                            width: 0.28rem;
                            height: 0.06rem;
                            margin-left: -0.14rem;
                            background: #ad701c;
                            border-radius: 1px;
                        }
                    }

                    .t2.spec {
                        &::after {
                            background: #ab3137;
                        }
                    }
                }
            }
        }

        .content {
            margin-top: -80px;
            padding: 30px;
        }
    }

    .fixed-btn {
        height: 2rem;
        .agreement {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0.3rem 0;
        }
        .btn {
            display: flex;
            justify-content: center;
            width: 80%;
            margin: 0px auto;

            font-size: 0.3rem;
            height: 0.8rem;
            line-height: 0.8rem;
        }
    }
}
