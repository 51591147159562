.introduce_popup_container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #f5f7fb;
  background-image: linear-gradient(180deg, #386ef6, #448af5 49%, rgba(83,164,243,0));
  background-size: 100% 8.75rem;
  background-repeat: no-repeat;
  .left_back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.4rem 0.32rem 0.2rem 0.32rem;
    width: 0.4rem;
    height: 0.4rem;
  }
  .content_wrap {
    margin-top: 0.32rem;
    padding: 0 0.32rem;
    position: absolute;
    top: 0.9rem;
    left: 0;
    right: 0;
    bottom: 1.76rem;
    overflow: auto;
    .intro_title {
      color: #fff;
      font-size: .48rem;
      font-weight: 600;
      font-family: SFDisplaySemibold;
    }
    .intro_subtitle {
      margin-top: 0.24rem;
      color: rgba(255, 255, 255, 0.7);
      font-size: .26rem;
    }
    .intro_card_info {
      margin-top: 0.28rem;
      background: #fff;
      box-shadow: 0 4px 40px 1px rgba(0, 0, 0, 0.03);
      border-radius: 0.16rem;
      padding:0.24rem;
      .intro_card_info_content {
        background: #f0f3ff;
        border-radius: 0.08rem;
        padding: 0.32rem 0.12rem;
        display: flex;
        justify-content: space-between;
        color: #6d87a8;
        font-size: .24rem;
        .item {
          text-align: center;
          .icon {
            display: inline-block;
            margin-bottom: 0.16rem;
            width: 0.64rem;
          }
        }
      }
    }
    .intro_detail {
      margin-top: 0.32rem;
      background: #fff;
      border-radius: 0.16rem;
      padding: 0.24rem;
      .item {
        margin-top: 0.32rem;
        &:first-child {
          margin-top: 0.1rem;
        }
        .name {
          color: #1a293e;
        }
        .content {
          margin-top: 0.16rem;
          color: rgba(62,83,108,.5);
        }
      }
    }
  }
  .popop_footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.24rem 0.48rem;
    background: #fff;
    box-shadow: 0 4px 40px 1px rgba(0, 0, 0, 0.03);
    .submit_btn {
      height: 0.96rem;
      line-height: .96rem;
      text-align: center;
      background: linear-gradient(90deg,#3875f6,#53a4f3);
      border-radius: 0.16rem;
      color: #fff;
      font-size: .36rem;
    }
  }
}
