.buy_popup_container {
  width: 100%;
  height: 100%;
  background: #f5f7fb;
  background-image: linear-gradient(180deg, #386ef6, #448af5 49%, rgba(83,164,243,0));
  background-size: 100% 8.75rem;
  background-repeat: no-repeat;
  .popup_header {
    position: relative;
    padding: 0.4rem 0.32rem 0.2rem 0.32rem;
    .left_back {
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  .popup_content {
    position: relative;
    padding: 0 0.32rem;
    .detail_banner {
      position: relative;
      padding-top: 0.45rem;
      padding-bottom: 0.32rem;
      .img_banner {
        position: absolute;
        bottom: 0;
        right: 0;
        display: inline-block;
        width: 4rem;
        height: auto;
        vertical-align: middle;
      }
      .title {
        color: #fff;
        font-size: .48rem;
        font-weight: 600;
      }
      .sub_title {
        position: relative;
        color: hsla(0,0%,100%,.7);
        font-size: .26rem;
        margin-top: 0.24rem;
      }
    }
    .detail_info {
      position: relative;
      margin-bottom: 0.32rem;
      background: #fff;
      border-radius: 0.12rem;
      padding: 0.24rem;
      .item_header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding-bottom: 0.24rem;
        border-bottom: 1px solid #f0f3ff;
        .item_info {
          display: flex;
          align-content: center;
          align-items: center;
          color: #081724;
          font-size: .4rem;
          font-family: DINMittelschrift;
          .item_icon {
            width: 0.72rem;
            margin-right: 0.16rem;
          }
        }
        .action {
          text-align: right;
          .name {
            color: #6d87a8;
            font-size: .26rem;
          }
          .value {
            margin-top: 0.04rem;
            color: #ff8d39;
          }
        }
      }
      .item_detail {
        margin-top: 0.24rem;
        display: flex;
        justify-content: space-between;
        .detail_item {
          .name {
            color: #6d87a8;
            font-size: .26rem;
          }
          .value {
            margin-top: 0.04rem;
            color: #ff8d39;
          }
        }
      }
      .item_logos {
        margin-top: 0.24rem;
        height: 0.88rem;
        background: #f5f7fb;
        border-radius: 0.12rem;
        padding-left: 0.24rem;
        display: flex;
        align-content: center;
        align-items: center;
        .logo_icon {
          margin-right: 0.24rem;
          width: 0.4rem;
          height: 0.4rem;
        }
      }
    }
    .detail_deal {
      margin-top: 0.4rem;
      padding: 0.24rem 0.24rem 0.32rem;
      background: #fff;
      border-radius: 0.12rem;
      .deal_title {
        color: #081724;
        font-size: .32rem;
      }
      .input_content {
        margin-top: 0.16rem;
        position: relative;
        height: 0.88rem;
        background: #f9fafb;
        border: 1px solid #e6eaee;
        border-radius: 0.12rem;
        .amount_input {
          width: 100%;
          height: 100%;
          padding-left: 0.4rem;
          padding-right: 1.8rem;
          box-sizing: border-box;
          background: transparent;
          color: #081724;
          font-size: .56rem;
          font-weight: 700;
          font-family: DINMittelschrift;
        }
        .max {
          position: absolute;
          right: 0.16rem;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 1.3rem;
          height: 0.56rem;
          line-height: .56rem;
          background: #e9edef;
          border-radius: 0.28rem;
          color: #4083f5;
          font-size: .24rem;
          text-align: center;
        }
      }
      .cash_tips {
        margin-top: 0.32rem;
        font-size: .26rem;
        color: rgba(62,83,108,.6);
      }
    }
    .detail_submit {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.24rem 0.48rem;
      background: #fff;
      .submit_btn {
        height: 0.96rem;
        line-height: .96rem;
        text-align: center;
        background: linear-gradient(90deg,#3875f6,#53a4f3);
        border-radius: 0.16rem;
        color: #fff;
        font-size: .36rem;
      }
    }
  }
}
