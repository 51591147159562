.withdraw_way {
  width: 345px;
  //height: 340px;
  background: #FFFFFF;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 15px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
    color: #3E536C;
    line-height: 20px;
  }

  .ways {
    margin-top: 10px;

    .item {
      display: flex;
      align-items: center;
      height: 40px;
      background: #FAFAFA;
      border-radius: 4px;
      margin-bottom: 15px;
      padding: 10px 15px;
      font-size: 14px;
      font-weight: 400;
      color: #113063;
      position: relative;

      .icon {
        width: 20px;
        margin-right: 8px;
      }
    }

    .item.active {
      border: 1px solid #CCCCCC;
    }

    .ratio {
      width: 14px;
      position: absolute;
      right: 15px;
      top: 13px;
    }
  }

  .desc {
    font-size: 12px;
    font-weight: 400;
    color: #A0ACC0;
    line-height: 17px;
  }

  .next-btn {
    width: 310px;
    height: 50px;
    background: linear-gradient(90deg, #4A74F0 0%, #5EA2F2 100%);
    border-radius: 4px;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 32px;
  }
}
