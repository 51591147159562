.product_wrap {
  margin-top: 0.4rem;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 0.32rem;
    color: #081724;
    font-size: .32rem;
    .icon_doubt {
      width: 0.28rem;
      height: 0.28rem;
      margin-left: 0.1rem;
    }
  }
}
