.convertBox{
    .fromBox{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.02667rem solid #ebedf0;
        padding-bottom: 10px;
        .fromName{
            display:flex;
            align-items: center;
            margin-top: 10px;
        }
        .rightFrom{
            // display: flex;
            // width: 100%;
            .input_right{
                display: flex;
                align-items: center;
            // margin-top: 0.16rem;
            // position: relative;
            // height: 0.88rem;
            // background: #f9fafb;
            // border: 1px solid #e6eaee;
            // border-radius: 0.12rem;
            .input {
                // padding-left: 0.4rem;
                // padding-right: 1.8rem;
                box-sizing: border-box;
                background: transparent;
                color: #081724;
                // font-size: 0.56rem;
                text-align: right;
                width: 90%;
                font-size: 0.36rem;
                font-weight: 700;
                font-family: DINMittelschrift;
                border: none;
            }
            .max{
                margin-left: 10px;
            }
            }
        }
    }
    .toBox{
        margin-top: 10px;
    }
}
.usdtImg{
    width: 30px;
    height: 30px;
    margin-right: 8px;
}
.chooseCoin{
    margin-top: 10px;
    color: #1652f0;
}
.coin {
    // padding: 20px 0;
    .coinItem {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      .name {
        font-size: 14px;
        color: #23262f;
      }
      .money {
        font-size: 14px;
        color: rgb(140, 147, 159);
      }
    }
  }