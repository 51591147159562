.record_loans_popup_container {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #386ef6, #448af5 49%, rgba(83, 164, 243, 0));
    background-size: 100% 8.75rem;
    background-repeat: no-repeat;
    // background-color: #f9f9f9;
    .popup_header {
        position: relative;
        padding: 0.32rem;
        text-align: center;
        font-size: 0.36rem;
        color: #fff;
        .icon_left {
            position: absolute;
            left: 0.32rem;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 0.4rem;
        }
    }
    .popup_content {
        position: relative;
        padding: 0.32rem;
        .record_content {
            // padding: 0.3rem;
            .record {
                padding: 0.3rem;
                background-color: #fff;
                border-radius: 10px;
                color: #3f3f3f;
                font-size: 14px;
                margin-bottom: 30rpx;

                .item {
                    display: flex;
                    justify-content: space-between;
                    margin: 0.2rem 0;

                    .desc {
                        display: flex;
                        flex-direction: column;

                        .name {
                            color: #8a8a8a;
                        }

                        .number {
                            margin-top: 0.2rem;
                            color: #000;
                        }
                    }
                }

                .grey {
                    color: #8a8a8a;
                }

                .tags {
                    display: flex;
                    flex-wrap: wrap;

                    .tag {
                        display: inline-block;
                        margin-right: 0.2rem;
                        padding: 0.1rem 0.3rem;
                        border-radius: 3px;
                        color: #123f7d;
                        background: #dfedff;
                        margin-bottom: 0.2rem;

                        &.red {
                            color: #fff !important;
                            background: #fa0000 !important;
                        }
                    }
                }

                .status {
                    &.ok {
                        color: #009d1e;
                    }
                }
            }
        }
    }
}
