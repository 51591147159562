.market_list {
  .market_item {
    margin-bottom: 0.16rem;
    padding: 0 0.2rem;
    background: #fff;
    border-radius: 0.12rem;
    .item_main {
      padding: 0.36rem 0 0.28rem 0;
      display: flex;
      justify-content: space-between;
      color: #3e536c;
      .name {
        display: flex;
        align-content: center;
        align-items: center;
        font-weight: 500;
        .icon {
          margin-right: 0.16rem;
          width: 0.56rem;
          height: 0.56rem;
        }
      }
      .value {
        display: flex;
        align-content: center;
        align-items: center;
        font-weight: 500;
        .icon_arrow {
          width: 0.4rem;
          height: 0.4rem;
          margin-left: 0.16rem;
        }
      }
    }
    .item_detail {
      .header {
        padding: 0.14rem 0;
        border-top: 1px solid #eceff6;
        display: flex;
        justify-content: space-between;
        color: #3e536c;
        .label {
          &:nth-child(1) {
            width: 2.36rem;
          }
          &:nth-child(2) {
            width: 2.1rem;
          }
          &:nth-child(3) {
            text-align: right;
            width: 1.6rem;
          }
        }
      }
      .list {
        .coin_item {
          padding: 0.24rem 0;
          border-top: 1px solid #eceff6;
          display: flex;
          justify-content: space-between;
          color: #8995a4;
          .info {
            width: 2.36rem;
            display: flex;
            align-content: center;
            align-items: center;
            .icon {
              margin-right: 0.16rem;
              width: 0.44rem;
              height: 0.44rem;
            }
          }
          .value {
            width: 2.1rem;
          }
          .num {
            text-align: right;
            width: 1.6rem;
          }
        }
      }
    }
  }
}
