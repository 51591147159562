.password-box{
    margin-top: 10px;
    margin: 0;
}
.content_wrap{
    .row-password {
        margin: 20px 0;
        div:first-child{
            margin-bottom: 10px;
        }
    }
    .new-password{
        margin: 20px 0;
    }
}

.no-close-button .icon_close {
    display: none;
  }
  .rv-number-keyboard{
    z-index: 2005;

  }
  .join_popup_box{
    // position: relative;
    height: 100%;
    width: 100%;
    .join_popup{
        position: absolute;
        // top: 20%;
    }
    // top: 39%;
  }
   .rv-popup{
    // display: flex;
    // justify-content: center;
    //  z-index: 2003!important;
   }
  .join_popup_content{
    display: flex;
    justify-content: center;

  }
  .join_popup{
    top: 15%;
  }
  .rv-number-keyboard{
  z-index: 99999!important;
  }