.money_wrap {
  color: #fff;
  .value {
    font-size: .72rem;
    font-weight: 700;
    font-family: DINMittelschrift;
  }
  .info {
    font-size: .26rem;
    margin-top: 0.12rem;
  }
}
