.product_item {
  margin-bottom: 0.32rem;
  background: #fff;
  border-radius: 0.12rem;
  padding: 0.24rem;
  .header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-bottom: 0.24rem;
    border-bottom: 1px solid #f0f3ff;
    .info {
      display: flex;
      align-content: center;
      align-items: center;
      color: #081724;
      font-size: .4rem;
      font-family: DINMittelschrift;
      font-weight: 700;
      .icon {
        width: 0.72rem;
        margin-right: 0.16rem;
      }
    }
    .limited {
      color: #6d87a8;
      span {
        font-size: 0.3rem;
        font-weight: 600;
        color: #4b69fe;
      }
    }
  }
  .detail {
    margin-top: 0.24rem;
    display: flex;
    justify-content: space-between;
    .detail_item {
      &:last-child {
        text-align: right;
      }
      .name {
        color: #6d87a8;
        font-size: .26rem;
      }
      .value {
        margin-top: 0.04rem;
        color: #131f30;
      }
    }
  }
  .coins {
    margin-top: 0.2rem;
    height: 0.88rem;
    background: #f5f7fb;
    border-radius: 0.12rem;
    padding-left: 0.24rem;
    display: flex;
    align-content: center;
    align-items: center;
    .icon {
      margin-right: 0.24rem;
      width: 0.4rem;
      height: 0.4rem;
    }
  }
  .submit_btn {
    height: 0.96rem;
    margin-top: 0.3rem;
    line-height: .96rem;
    text-align: center;
    background: linear-gradient(90deg,#3875f6,#53a4f3);
    border-radius: 0.12rem;
    color: #fff;
    font-size: .36rem;
  }
  .is-disabled {
    color: #aaa;
    background: #eee;
  }
}
