.online_help {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  height: 1.2rem;
  margin-top: -1.8rem;
  padding: 0 0.2rem 0 0.24rem;
  background: #fff;
  border-radius: 0.12rem;
  justify-content: space-between;
  color: rgba(0,0,0,.8);
  font-weight: 700;
  .info {
    display: flex;
    align-content: center;
    align-items: center;
    .icon {
      width: 0.54rem;
      margin-right: 0.2rem;
    }
  }
  .arrow {
    width: 0.24rem;
  }
}
